import * as React from 'react';
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from '@/src/components/ui/tooltip';
import { ComponentType, ElementRef, forwardRef } from 'react';

type WithTooltips = {
  disabled?: boolean;
  disabledTooltip?: string;
  tooltip?: string;
  closeOnClick?: boolean;
  tooltipOptions?: React.ComponentPropsWithoutRef<typeof Tooltip> &
    React.ComponentPropsWithoutRef<typeof TooltipContent>;
};

export function withTooltips<P>(Component: ComponentType<P>) {
  // eslint-disable-next-line react/display-name
  return forwardRef<ElementRef<ComponentType<P>>, P & WithTooltips>(
    (
      {
        tooltip,
        disabledTooltip,
        tooltipOptions,
        closeOnClick,
        ...props
      }: P & WithTooltips,
      ref,
    ) => {
      if (tooltip || (props.disabled && disabledTooltip)) {
        return (
          <Tooltip {...tooltipOptions}>
            <TooltipTrigger
              componentDisabled={props.disabled}
              closeOnClick={closeOnClick}
              asChild
            >
              <Component ref={ref} {...(props as any)} />
            </TooltipTrigger>
            <TooltipContent {...tooltipOptions}>
              {props.disabled ? disabledTooltip || tooltip : tooltip}
            </TooltipContent>
          </Tooltip>
        );
      }
      return <Component ref={ref} {...(props as any)} />;
    },
  );
}
