import QueryString from 'qs';
import React from 'react';

export type MetaRequestContextProps = {
  userAgent: string | undefined;
  getQuery: () => QueryString.ParsedQs;
};
export const MetaRequestContext = React.createContext<MetaRequestContextProps>({
  userAgent: '',
  getQuery: () => ({}),
});
export const useRequestMeta = () => React.useContext(MetaRequestContext);
