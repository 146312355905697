export type ResponsiveSize = 'sm' | 'md' | 'lg';

export const ResponsiveDeviceSizes: Record<ResponsiveSize, number> = {
  sm: 600, // Small
  md: 1024, // Medium
  lg: 1200, // Large
};

export function getResponsiveValue<T extends { [size in ResponsiveSize]: any }>(
  map: T,
  currentSize: ResponsiveSize,
): T[keyof T] {
  if (currentSize === 'sm') return map['sm'] ?? map['md'] ?? map['lg'];
  if (currentSize === 'md') return map['md'] ?? map['lg'];
  if (currentSize === 'lg') return map['lg'];
  throw new Error('No size found');
}
