'use client';
import * as React from 'react';
import { Slot, Slottable } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { cn } from '@/src/lib/utils';
import { GtmEvent, trackGtmEvent } from '@/src/helpers/analytics.ts';

import { withTooltips } from '@/src/components/ui/hoc/withTooltips.tsx';

const buttonVariants = cva(
  'button inline-flex items-center justify-center rounded-md font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default:
          'bg-background-100 text-foreground hover:bg-background-50 ring-border/80 ring-1 data-[active=on]:bg-background-200 focus:active:bg-background-200',
        primary:
          'bg-primary ring-primary text-primary-foreground hover:bg-primary/90 focus:active:bg-primary/80',
        outline:
          'border data-[active=on]:bg-card border-input text-foreground hover:bg-foreground/5 hover:text-foreground',
        secondary:
          'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        destructive:
          'bg-destructive text-destructive-foreground hover:bg-destructive/90',

        // Special-Styles
        link: 'text-primary underline-offset-4 hover:underline',
        card: 'bg-card hover:bg-muted text-card-foreground',
        ghost: '',

        //
        // Outdated
        //
        fancy:
          '[&:not(:hover)]:bg-gradient-to-r from-secondary/80 via-secondary/70 to-secondary animate-bg text-secondary-foreground hover:bg-neutral-400 hover:text-accent-foreground',
        colorful:
          '[&:not(:hover)]:bg-gradient-to-r from-pink-300 to-violet-300 text-white hover:bg-accent hover:text-accent-foreground',
      },
      size: {
        none: '',
        default: 'h-10 px-4 py-2',
        sm: 'h-9 rounded-md px-3',
        lg: 'rounded-md text-lg px-5 py-4',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof buttonVariants> & {
    asChild?: boolean;
    arrow?: boolean;
    clickTrackGtmEvent?: GtmEvent;
  };

const InternButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      children,
      arrow = false,
      asChild = false,
      clickTrackGtmEvent,
      onClick: parentOnClick,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button';

    const onClick: typeof parentOnClick = (event) => {
      if (clickTrackGtmEvent) {
        trackGtmEvent(clickTrackGtmEvent);
      }
      parentOnClick && parentOnClick(event);
    };

    return (
      <Comp
        className={cn(
          'group/btn',
          buttonVariants({ variant, size, className }),
        )}
        onClick={onClick}
        ref={ref}
        {...props}
      >
        <Slottable>{children}</Slottable>
        {arrow && (
          <span className="ml-2 !transition-transform duration-500 group-hover/btn:translate-x-1 group-disabled/btn:translate-x-0">
            &raquo;
          </span>
        )}
      </Comp>
    );
  },
);
InternButton.displayName = 'Button';

const Button = withTooltips(InternButton);

export { Button, buttonVariants };
