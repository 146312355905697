'use client';

import * as React from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import { cn } from '@/src/lib/utils';
import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { trackGtmEvent } from '@/src/helpers/analytics.ts';
import { children } from 'hastscript/lib/jsx-classic';

const TooltipProvider = TooltipPrimitive.Provider;

/**
 * Diese spielchen sind nötig für Safari kompabilität
 */
const TooltipContext = React.createContext<{
  setOpen: Dispatch<SetStateAction<boolean>>;
  setCloseOnClick: Dispatch<SetStateAction<boolean>>;
}>({
  setOpen: () => {},
  setCloseOnClick: () => {},
});
const useInnerContext = () => React.useContext(TooltipContext);

type TooltipOptions = {
  trackGtmOpenLayerLabel?: string;
  withProvider?: boolean;
};

const Tooltip = ({
  trackGtmOpenLayerLabel,
  withProvider = true,
  ...props
}: React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Root> &
  TooltipOptions) => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [isCloseOnClick, setCloseOnClick] = useState<boolean>(true);

  useEffect(() => {
    if (trackGtmOpenLayerLabel && isOpen) {
      trackGtmEvent({
        event: 'openLayer',
        label: `tooltip-open-${trackGtmOpenLayerLabel}`,
      });
    }
  }, [isOpen]);

  const mayWrapProvider = useCallback(
    (children: ReactElement) => {
      if (withProvider) {
        return <TooltipProvider delayDuration={50}>{children}</TooltipProvider>;
      }
      return children;
    },
    [withProvider],
  );

  return mayWrapProvider(
    <TooltipContext.Provider value={{ setOpen, setCloseOnClick }}>
      <TooltipPrimitive.Root
        open={isOpen}
        onOpenChange={(e) => {
          // Lasse keine Close anfragen mehr zu
          if ((!isCloseOnClick && e) || isCloseOnClick) setOpen(e);
        }}
        {...props}
      />
    </TooltipContext.Provider>,
  );
};
Tooltip.displayName = TooltipPrimitive.Tooltip.displayName;

const TooltipTrigger = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Trigger> & {
    componentDisabled?: boolean;
    closeOnClick?: boolean;
  }
>(({ componentDisabled, closeOnClick = true, ...props }, ref) => {
  const innerContext = useInnerContext();

  useEffect(() => {
    innerContext.setCloseOnClick(!componentDisabled && closeOnClick);
  }, [componentDisabled, closeOnClick]);

  return (
    <TooltipPrimitive.Trigger
      onClick={() => innerContext.setOpen(true)}
      onMouseLeave={() => innerContext.setOpen(false)}
      onFocus={() => setTimeout(() => innerContext.setOpen(true), 0)} // Überschreiben von base
      onBlur={() => innerContext.setOpen(false)}
      onTouchStart={() => innerContext.setOpen((e) => !e)}
      data-has-tooltip="true"
      ref={ref}
      {...props}
    />
  );
});
TooltipTrigger.displayName = TooltipPrimitive.TooltipTrigger.displayName;

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 10, ...props }, ref) => (
  <TooltipPrimitive.Content
    sticky="always"
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      'bg-popover text-popover-foreground animate-in border-secondary/50 fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 w-max-[100vw] z-30 max-w-sm overflow-hidden rounded-md border px-3 py-3 text-sm font-light shadow-md sm:w-auto',
      className,
    )}
    {...props}
  />
));
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

export { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider };
