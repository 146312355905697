import { PropsWithChildren, useMemo } from 'react';
import { cn, isProd } from '@/src/lib/utils.ts';
import { cva, VariantProps } from 'class-variance-authority';

const emojiVariants = cva('', {
  variants: {
    variant: {
      grayscale: 'grayscale-[0.4]',
    },
  },
  defaultVariants: {
    variant: 'grayscale',
  },
});

type Props = PropsWithChildren & VariantProps<typeof emojiVariants>;

export function Emoji({ children, variant }: Props) {
  const displayEmoji = useMemo(() => {
    if (typeof children === 'string') return children;
    if (
      Array.isArray(children) &&
      children.length === 1 &&
      typeof children[0] === 'string'
    )
      return children[0];
  }, [children]);

  if (!isProd() && typeof displayEmoji !== 'string') {
    console.log(displayEmoji);
    throw new Error(`Emoji: Children is no emoji but ${typeof displayEmoji}`);
  }

  return (
    <span
      className={cn(emojiVariants({ variant }))}
      // style={{ textShadow: '0 0 0 hsl(var(--muted-foreground))' }}
    >
      {displayEmoji}
    </span>
  );
}
