'use client';

import React, {
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { cn, isSSR } from '@/src/lib/utils.ts';
import { debounce } from 'lodash';
import ClientLink from '@/src/components/layout/ClientLink.tsx';
import FullsizeMenu from '@/src/components/layout/navigation/FullsizeMenu.tsx';
import ButtonLink from '@/src/components/ui/atoms/button-link.tsx';
import { useTranslations } from '@/src/hooks/useTranslations.tsx';
import { useLanguageContext } from '@/src/components/layout/ProvideLanguageContext.tsx';
import useIsMobile from '@/src/hooks/useIsMobile.ts';
import LogoWithText from '@/src/components/layout/LogoWithText.tsx';
import { usePrevious } from '@uidotdev/usehooks';
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuList,
  NavigationMenuTrigger,
} from '@/src/components/ui/navigation-menu.tsx';
import { NavigationProductsSlidingMenu } from '@/src/components/layout/navigation/navigation-products-sliding-menu.tsx';
import {
  DiscoveryMenu,
  useMenu,
} from '@/src/components/layout/navigation/navigation-items.tsx';
import { NavigationContext } from '@/src/components/layout/navigation/navigation-context.ts';
import { useDarkMode } from '@/src/components/layout/navigation/dark-mode.ts';

export const useNavigationContext = () => React.useContext(NavigationContext);
export type MenuState = 'SMALL' | 'STICKY-BORDERED' | 'BORDERED' | 'AUTO';

export default function Navigation({
  children,
  randomSeed,
  initialMenuState,
  initialDarkMode,
}: PropsWithChildren<{
  initialMenuState: MenuState;
  initialDarkMode: boolean;
  randomSeed: number;
}>) {
  const { usePathname } = useLanguageContext();
  const trans = useTranslations();
  const pathname = usePathname();
  const isMobile = useIsMobile();
  const [menuDarkMode, setMenuDarkMode] = useState<boolean>(initialDarkMode);
  const { darkModeEnabled, setDarkModeEnabled } = useDarkMode([
    menuDarkMode,
    setMenuDarkMode,
  ]);

  // useEffect(() => {
  //   setDarkModeEnabled(true);
  //   setMenuDarkMode(true);
  // }, []);

  const [isSticky, setSticky] = useState(false);
  const [isSmall, setSmall] = useState(false);
  const [menuState, setMenuState] = useState<MenuState>(
    isMobile ? 'STICKY-BORDERED' : initialMenuState,
  );
  const [mobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);

  // Transitions initial verhindern
  const transitionEnabled = useRef(false);
  useEffect(() => {
    setTimeout(() => (transitionEnabled.current = true), 100);
  }, []);

  //
  // Mini Event
  //
  const prevScroll = usePrevious(isSSR() ? 0 : window.scrollY);
  useEffect(() => {
    const onScroll = () => {
      let shouldAutoSizeSmall = false;
      if (!isSSR()) {
        shouldAutoSizeSmall = window.scrollY > 50;
      }
      setSmall(menuState === 'AUTO' && shouldAutoSizeSmall);
    };
    const debounceScroll = debounce(onScroll, 50, { maxWait: 50 });

    window.addEventListener('scroll', debounceScroll);
    onScroll();

    return () => {
      window.removeEventListener('scroll', debounceScroll);
      debounceScroll.cancel();
    };
  }, [menuState]);

  //
  // Page-Transitions
  //
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    const onPageTransitionStart = () => {
      setIsLoading(true);
    };
    const onPageTransitionEnd = () => {
      setIsLoading(false);
    };

    window.addEventListener('pageTransitionStart', onPageTransitionStart);
    window.addEventListener('pageTransitionEnd', onPageTransitionEnd);
    return () => {
      window.removeEventListener('pageTransitionStart', onPageTransitionStart);
      window.removeEventListener('pageTransitionEnd', onPageTransitionEnd);
    };
  }, []);

  useEffect(() => {
    setSticky(['AUTO', 'STICKY-BORDERED'].includes(menuState));
  }, [menuState]);

  const outerNavigationRef = useRef<HTMLDivElement>(null);

  const calculatedDarkMode: boolean = menuDarkMode && !isMobile;
  const calculatedMenuState: MenuState = isMobile
    ? 'STICKY-BORDERED'
    : menuState;

  const discoveryMenu = useMenu(DiscoveryMenu);

  const navigationHeightOffset = useMemo(() => {
    return (
      (outerNavigationRef?.current?.getBoundingClientRect().height ?? 0) + 3
    );
  }, [outerNavigationRef.current, isSticky]);

  return (
    <NavigationContext.Provider
      value={{
        randomSeed: randomSeed,
        state: menuState,
        navigationHeightOffset,
        scrollToElementById: (elementId, props) => {
          const element = document.getElementById(elementId);
          if (element) {
            // Berechnet die Position des Elements relativ zum Dokumentanfang
            const elementPosition =
              element.getBoundingClientRect().top + window.scrollY;

            // Berücksichtigt den Offset und scrollt zur berechneten Position
            window.scrollTo({
              top:
                elementPosition - navigationHeightOffset - (props?.offset ?? 0),
              behavior: 'smooth',
            });
          }
        },
        setMenuDarkMode: (darkModeEnabled) => {
          setMenuDarkMode(darkModeEnabled);
        },
        setState: (state) => {
          setMenuState(state);
        },
        isFullsizeMenuOpen: mobileMenuOpen,
        setFullsizeMenuOpen: (state) => {
          setMobileMenuOpen(state);
        },
      }}
    >
      <div
        className={cn(
          'fixed top-0 z-50 w-full',
          { invisible: !isSticky || isMobile },
          { '!visible': isLoading },
          { dark: calculatedDarkMode },
        )}
      >
        <div
          className={cn(
            'bg-primary/10 h-1.5 w-full overflow-hidden',
            { 'h-0.5': isSmall },
            { 'bg-primary/30': isLoading },
          )}
        >
          <div
            className={cn(
              'origin-left-right bg-primary/80 h-full w-full origin-left translate-x-full',
              { 'animate-progress': isLoading },
            )}
          ></div>
        </div>
      </div>
      <nav
        ref={outerNavigationRef}
        className={cn(
          'pointer-events-none relative z-50 shrink-0',
          { 'transition-[height]': transitionEnabled.current },
          // Das folgende sieht wild aus, weil es dafür sorgt dass man den header mit allen größen UP ziehen kann
          {
            'h-[85px] pt-[15px] [&:has(_~_*_>_.header-up)]:-mb-[105px] [&:has(_~_.header-up)]:-mb-[105px] [&_~_*_>_.header-up]:pt-[105px] [&_~_.header-up]:pt-[105px]':
              ['BORDERED', 'STICKY-BORDERED'].includes(calculatedMenuState),
          },
          {
            'mt-0 h-[100px] lg:mt-[30px] lg:h-[150px] [&:has(_~_*_>_.header-up)]:-mb-[100px] [&:has(_~_.header-up)]:-mb-[100px] lg:[&:has(~_*_>_.header-up)]:-mb-[180px] lg:[&:has(~_.header-up)]:-mb-[180px] [&_~_*_>_.header-up]:pt-[100px] lg:[&_~_*_>_.header-up]:pt-[180px] [&_~_.header-up]:pt-[100px] lg:[&_~_.header-up]:pt-[180px]':
              calculatedMenuState === 'AUTO',
          },
          {
            'mt-0 !h-[70px] lg:mt-[30px] [&:has(_~_*_>_.header-up)]:-mb-[70px] [&:has(_~_.header-up)]:-mb-[70px] [&_~_*_>_.header-up]:pt-[70px] [&_~_.header-up]:pt-[70px]':
              calculatedMenuState === 'SMALL',
          },
          { 'sticky top-0': isMobile || isSticky },
        )}
      >
        <div
          style={{ pointerEvents: 'all' }}
          className={cn(
            'h-full',
            { dark: calculatedDarkMode },
            {
              'transition-[height,box-shadow] duration-300 ease-in-out':
                transitionEnabled.current,
            },
            { 'h-[70px]': calculatedMenuState === 'SMALL' || isSmall },
            {
              'bg-background border-b-2':
                calculatedMenuState === 'AUTO' && isSticky && isSmall,
            },
            {
              'mx-4': ['BORDERED', 'STICKY-BORDERED'].includes(
                calculatedMenuState,
              ),
            },
          )}
        >
          <div
            className={cn(
              'container flex h-full w-full flex-row items-center justify-between py-0.5',
              {
                'shadow-zinc-150 shadow-xl':
                  isSmall && calculatedMenuState === 'STICKY-BORDERED',
              },
              {
                'transition-[box-shadow] duration-500':
                  transitionEnabled.current,
              },
              {
                'rounded-2xl border bg-zinc-100 ring-1 ring-zinc-300 ring-offset-2 lg:justify-start dark:bg-neutral-800 dark:ring-white':
                  ['BORDERED', 'STICKY-BORDERED'].includes(calculatedMenuState),
              },
            )}
          >
            <ClientLink
              className="h-full transition-transform duration-300 hover:scale-105"
              clickTrackGtmEvent={{
                event: 'navLinkClick',
                link: '/',
                label: 'logo',
              }}
              href="/"
            >
              <LogoWithText darkMode={calculatedDarkMode} className="h-full" />
            </ClientLink>

            <NavigationMenu className="ml-8 hidden lg:block">
              <NavigationMenuList>
                <NavigationMenuItem>
                  <NavigationMenuTrigger>
                    {trans('NAVIGATION.PRODUCTS')}
                  </NavigationMenuTrigger>
                  <NavigationMenuContent>
                    <NavigationProductsSlidingMenu items={discoveryMenu} />
                  </NavigationMenuContent>
                </NavigationMenuItem>
                {/*<NavigationMenuItem>*/}
                {/*  <NavigationMenuTrigger>*/}
                {/*    {trans('NAVIGATION.JOURNAL')}*/}
                {/*  </NavigationMenuTrigger>*/}
                {/*  <NavigationMenuContent>*/}
                {/*    <NavigationSlidingMenu items={discoveryMenu} />*/}
                {/*  </NavigationMenuContent>*/}
                {/*</NavigationMenuItem>*/}
              </NavigationMenuList>
            </NavigationMenu>

            {/*<Button onClick={() => setDarkModeEnabled((e) => !e)}>*/}
            {/*  Toggle*/}
            {/*</Button>*/}

            <ButtonLink
              href="/discovery"
              variant="primary"
              clickTrackGtmEvent={{
                event: 'navLinkClick',
                link: '/discovery',
                label: 'prom',
              }}
              className={cn(
                'hidden rounded-2xl md:inline-flex',
                {
                  'ml-auto': ['BORDERED', 'STICKY-BORDERED'].includes(
                    calculatedMenuState,
                  ),
                },
                { '!hidden': pathname === '/discovery/' },
              )}
              size="lg"
            >
              {trans('NAVIGATION.SEARCH_INFLUENCER')}
            </ButtonLink>
            <FullsizeMenu
              menuOpen={mobileMenuOpen}
              className="ml-4 block lg:hidden"
            />
          </div>
        </div>
      </nav>
      {children}
    </NavigationContext.Provider>
  );
}
