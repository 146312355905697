import {
  useTranslations as originalUseTranslations,
  useFormatter,
  useLocale,
} from 'next-intl';

/**
 * Ist eine art Middleware für 'next-intl'
 */
export const useTranslations: typeof originalUseTranslations = (...args) => {
  const translations = originalUseTranslations(...args);
  const currentLocale = useLocale();

  const proxy = function (label: string, ...rest: unknown[]) {
    let sourceTranslation = undefined;
    try {
      sourceTranslation = translations(label as any, ...(rest as any)); // proxy
    } catch (error) {}
    if (sourceTranslation) {
      return sourceTranslation;
    } else {
      return (
        <span className="text-foreground bg-red-500 p-0.5 text-xs">
          {`[${label}]`}
        </span>
      );
    }
  } as any;
  proxy.rich = translations.rich;

  return proxy;
};

export { useFormatter };
