'use client';
import { Button, ButtonProps } from '@/src/components/ui/button.tsx';
import ClientLink, {
  ClientLinkProps,
} from '@/src/components/layout/ClientLink.tsx';
import React from 'react';
import { LinkProps } from 'next/link';

type Variants = Pick<ClientLinkProps, 'variant'> | Pick<ButtonProps, 'variant'>;

type ButtonLinkProps = {
  arrow?: boolean;
} & React.LinkHTMLAttributes<HTMLLinkElement> & {
    href: string;
    target?: string;
  } & LinkProps &
  Omit<ClientLinkProps, 'variant'> &
  Omit<ButtonProps, 'variant'> &
  Variants;

export default function ButtonLink(props: ButtonLinkProps) {
  const {
    children,
    href,
    target,
    locale,
    arrow = true,
    clickTrackGtmEvent,
    ...other
  } = props;

  return (
    <Button arrow={arrow} asChild {...other}>
      <ClientLink
        href={href}
        clickTrackGtmEvent={clickTrackGtmEvent}
        locale={locale}
        target={target}
      >
        {children}
      </ClientLink>
    </Button>
  );
}

export { ButtonLink };
