import React from 'react';
import { MenuState } from '@/src/components/layout/navigation/Navigation.tsx';

export interface NavigationContextProps {
  randomSeed: number;
  state: MenuState | undefined;
  darkMode?: boolean;
  setState: (state: MenuState) => void;
  setMenuDarkMode: (darkMode: boolean) => void;
  scrollToElementById: (
    elementId: string,
    props?: {
      offset?: number;
    },
  ) => void;
  isFullsizeMenuOpen: boolean;
  setFullsizeMenuOpen: (state: boolean) => void;
  navigationHeightOffset: number;
}
export const NavigationContext = React.createContext<NavigationContextProps>({
  randomSeed: 0,
  state: undefined,
  setState: () => null,
  darkMode: false,
  setMenuDarkMode: () => null,
  isFullsizeMenuOpen: false,
  scrollToElementById: () => null,
  setFullsizeMenuOpen: () => null,
  navigationHeightOffset: 0,
});
