import IntlConfig from 'use-intl/dist/types/src/core/IntlConfig';
import { Emoji } from '@/src/components/ui/atoms/emoji.tsx';
import React from 'react';

export const IntlNextConfigPresets: Partial<IntlConfig> = {
  defaultTranslationValues: {
    important: (e) => <b className="font-bold">{e}</b>,
    contrast: (e) => <span className="i18n-contrast text-secondary">{e}</span>,
    emoji: (e) => <Emoji>{e}</Emoji>,
  },
  now: new Date(),
  formats: {
    dateTime: {
      short: {
        day: 'numeric',
        month: 'short',
        // year: 'numeric'
      },
    },
  },
};
