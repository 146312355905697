import { isSSR } from '@/src/lib/utils.ts';
import { useMediaQuery } from '@/src/hooks/useMediaQuery.ts';
import { useRequestMeta } from '@/src/lib/meta-request-context.ts';
import isMobile from 'is-mobile';
import { useEffect, useState } from 'react';
import { ResponsiveDeviceSizes } from '@/src/lib/responsive.ts';

type Props = {
  mobileBelow?: 'sm' | 'md' | 'lg' | number;
};

export default function useIsMobile({ mobileBelow = 'sm' }: Props = {}) {
  const { userAgent } = useRequestMeta();
  const uaIsMobile = isMobile({ ua: userAgent });
  const [mobile, setMobile] = useState(uaIsMobile);

  const maxWidth =
    typeof mobileBelow === 'number'
      ? mobileBelow
      : ResponsiveDeviceSizes[mobileBelow];

  const widthIsMobile = !isSSR()
    ? useMediaQuery(`(max-width: ${maxWidth}px)`)
    : false;

  useEffect(() => {
    if (mobile != widthIsMobile) {
      setMobile(widthIsMobile);
    }
  }, [widthIsMobile]);

  return mobile;
}
